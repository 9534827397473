import React, {
} from "react";
import { Link, withRouter } from 'react-router-dom';
import { userService, propertyService } from '../../_services';



class ContactModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.user
        }
        this.handleCloseClick = this.handleCloseClick.bind(this);
        this.loadScript = this.loadScript.bind(this);
    }

    loadScript(src) {
        const script = document.createElement("script");
        script.src = src;
        script.async = false;
        document.body.appendChild(script);
    }



    componentDidMount() {
        const { handleModalCloseClick } = this.props;
        window.$(this.modal).modal('show');
        window.$(this.modal).on('hidden.bs.modal', handleModalCloseClick);



    }
    handleCloseClick() {
        const { handleModalCloseClick } = this.props;
        window.$(this.modal).modal('hide');
        handleModalCloseClick();
    }



    render() {

        const { user } = this.state

        return (
            <div>
                <div className="modal fade" ref={modal => this.modal = modal}
                    id="contactModal" tabIndex="-1" role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog modal-sm" role="document">

                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title arabic" id="exampleModalLabel">تواصل مع صاحب الاعلان</h5>
                            </div>
                            <div className="modal-body">

                                <div className="form-group">
                                    <div className="btn-group-justified">
                                        <p className="" style={{ "textDirection": "rtl" }}>للتواصل مع صاحب الاعلان مباشرة برجاء الاتصال علي </p>
                                        <p>{user.phone_number}</p>

                                    </div>
                                </div>


                            </div>
                            <div class="modal-footer">

                                <button id="cancel" type="button" className="btn btn-default arabic-english" data-dismiss="modal">
                                    غلق</button>




                            </div>
                        </div>

                    </div>
                </div>
            </div >



        );
    }
}


export default ContactModal;