import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import SearchFeatureFooter from './SearchFeatureFooter'

class SearchPropertyCard extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            property: this.props.property
        }

        this.onImage = this.onImage.bind(this)

    }


    onImage(img) {

        if (!img)
            return "images/log.png"
        else
            return img

    }

    render() {

        const property = this.state.property;
        return (

            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <Link className="card"
                    style={{ "borderRadius": "0px" }}
                    to={{
                        pathname: `/properties/${property.property_id}#${property.property_id}`,
                        property: property
                    }}
                >
                    <div className="figure" style={{ "borderTopLeftRadius": "0x", "borderTopRightRadius": "0px" }}>
                        <img src={this.onImage(property.details.images[0])} alt="image" />
                        <div className="figCaption">
                            <div className="english">&nbsp;{property.details.price} L.E</div>
                        </div>
                        <div className="figView">
                            <span className="icon-eye"></span>
                        </div>
                        <div className="figType">
                            {
                                property.details && property.details.purpose ?
                                    <p className="arabic-english"> {property.details.purpose} </p> : null
                            }

                            <i className="arabic-english">{property.details.price}</i>

                        </div>
                    </div>
                    <h2 className="arabic-english">{property.details.title}</h2>
                    <div id="cardAddress" className="cardAddress">
                        <span className="icon-pointer"></span> {property.details.location.address}

                    </div>
                    <div className="cardRating">
                        <span className="fa fa-star"></span>
                        <span className="fa fa-star"></span>
                        <span className="fa fa-star"></span>
                        <span className="fa fa-star"></span>
                        <span className="far fa-star"></span>
                    </div>
                    <ul className="cardFeat">
                        <SearchFeatureFooter property={property}></SearchFeatureFooter>
                    </ul>
                    <div className="clearfix"></div>
                </Link>

            </div>



        )
    }
}

export default SearchPropertyCard;
