import React from 'react';
import Home from './components/Home/Home'
import TOS from './components/Home/TOS'
import PropertyTemplate from './components/property/PropertyTemplate'
import Template from './components/Templates/Template'
import Search from './components/Search/Search'
import {
  Router,
  Switch,
  Route,
} from "react-router-dom";
import PropertyForm from './components/forms/property-form';
import CompleteProfile from './components/forms/complete-profile';
import { PrivateRoute } from './components/Templates/PrivateRoute'
import Cookies from 'js-cookie';
import { userService } from './_services/user.service';
import Admin from './components/admin/admin-panel'
import Profile from './components/profile/Profile';
import ErrorBoundary from './components/error/ErrorBoundary'



class App extends React.Component {

  constructor(props) {
    super(props)
    this.state = {

    }
    this.loadScript = this.loadScript.bind(this);

  }

  loadScript(src, type) {
    const script = document.createElement("script");
    script.src = src;
    script.async = false;
    if (type)
      script.type = type;
    document.body.appendChild(script);

  }

  componentDidMount() {
    userService.loadUserInfo()
  }

  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/complete-profile" component={CompleteProfile} />

          <Template>
            <Switch>
            
              <PrivateRoute exact path="/users/profile/" component={Profile} />
              <PrivateRoute exact path="/properties/add/" component={PropertyForm} />
              <PrivateRoute exact path="/properties/:id/update/" component={PropertyForm} />
              <Route exact path="/properties/:id" component={PropertyTemplate} />
              <Route exact path="/search" component={Search} />
              {/* <PrivateRoute exact path="/admin" component={Admin} /> */}
              <Route exact path="/tos" component={TOS} />

            </Switch>
          </Template>

        </Switch>

      </div>)
  }
}

export default App;