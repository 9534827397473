import React from 'react';
// import './App.css';
import Script from 'react-load-script'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import Header from './Header'
import LeftSide from './LeftSide'

import $ from 'jquery';

import {
  Link

} from "react-router-dom";

// import './bootstrap.css'

class Template extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      property: null
    }
    this.removeBodyClass = this.removeBodyClass.bind(this);
    this.loadScript = this.loadScript.bind(this);

  }
  loadScript(src) {
    const script = document.createElement("script");
    script.src = src;
    script.async = true;
    document.body.appendChild(script);
  }
  removeBodyClass() {
    $("body").attr("class", "notransition");
  }
  componentDidMount() {
    this.removeBodyClass();
    // this.loadScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyCd0gcrclONvpyBt646zECD98jEM24sAcE&amp;libraries=geometry&amp;libraries=places');

  }
  render() {

    return (
      <div>
        {/* HEADER */}
        <Header></Header>
        {/* HEADER */}
        {/* LEFT SIDE */}
        <LeftSide></LeftSide>
        {/* LEFT SIDE */}
        <div id="wrapper">
          {this.props.children}
        </div>

      </div >
    )

  }
}



export default Template;
