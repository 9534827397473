import React from 'react';
// import './App.css';
import Script from 'react-load-script'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import { userService } from '../../_services/user.service'
import $ from 'jquery';

import {
  Link

} from "react-router-dom";


class Header extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      user: null

    }

  }

  componentDidMount() {
    userService.currentUser.subscribe(x => this.setState({ user: x }));
    // let user = userService.currentUserValue
    // if (user) {
    //   this.setState({
    //     user: user
    //   })
    // }

  }

  render() {

    const { user } = this.state

    return (
      <div>
        {/* HEADER */}
        < div id="header" >
          <div className="logo">
            <a href="/">
              <span className="fa fa-home marker "></span>
              <span className="logoText arabic-english">نيوجار</span>
            </a>
          </div>
          <a href="#" className="navHandler"><span className="fa fa-bars"></span></a>
          <div className="search ">
            <span className="searchIcon icon-magnifier"></span>
            <input type="text" placeholder="Search for houses, apartments..." />
          </div>

          {user ?
            <>
              <div className="headerUserWraper ">
                <a href="#" className="userHandler dropdown-toggle" data-toggle="dropdown"><span className="icon-user"></span><span className="counter">5</span></a>


                <a href="#" className="headerUser dropdown-toggle" data-toggle="dropdown">
                  <img className="avatar headerAvatar pull-left" src={user.facebook_id ? `https://graph.facebook.com/${user.facebook_id}/picture?type=normal` : `${user.profile_picture}`} alt="avatar" />
                  <div className="userTop pull-left">
                    <span className="headerUserName">{user.name}</span> <span className="fa fa-angle-down"></span>
                  </div>
                  <div className="clearfix"></div>
                </a>

                <div className="dropdown-menu pull-right userMenu" role="menu">
                  <div className="mobAvatar">
                    <img className="avatar mobAvatarImg" src="images/avatar-1.png" alt="avatar" />
                    <div className="mobAvatarName">John Smith</div>
                  </div>
                </div>
              </div>
              <div className="headerNotifyWraper hidden ">
                <a href="#" className="headerNotify dropdown-toggle" data-toggle="dropdown">
                  <span className="notifyIcon icon-bell"></span>
                  <span className="counter">5</span>
                </a>
                <div className="dropdown-menu pull-right notifyMenu" role="menu">
                  <div className="notifyHeader">
                    <span>Notifications</span>
                    <a href="#" className="notifySettings icon-settings"></a>
                    <div className="clearfix"></div>
                  </div>
                  <ul className="notifyList">
                    <li>
                      <a href="#">
                        <div className="notifyRound notifyRound-yellow fa fa-heart-o"></div>
                        <div className="pulse border-yellow"></div>
                        <div className="notify pull-left">
                          <div className="notifyName">It is a long established fact</div>
                          <div className="notifyTime">2 hours ago</div>
                        </div>
                        <div className="clearfix"></div>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <div className="notifyRound notifyRound-magenta fa fa-paper-plane-o"></div>
                        <div className="pulse border-magenta"></div>
                        <div className="notify pull-left">
                          <div className="notifyName">There are many variations</div>
                          <div className="notifyTime">1 day ago</div>
                        </div>
                        <div className="clearfix"></div>
                      </a>
                    </li>
                  </ul>
                  <a href="#" className="notifyAll">See All</a>
                </div>
              </div>
            </>
            : null}






          <a href="#" className="mapHandler"><span className="icon-map"></span></a>
          <div className="clearfix"></div>
        </div >
      </div>
    )

  }
}



export default Header;
