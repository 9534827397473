import React from 'react';
import FeatureFooter from '../property/FeatureFooter'
import {
    Link

} from "react-router-dom";

class PropertyCard extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            property: this.props.property
        }

        this.onImage = this.onImage.bind(this)

    }


    onImage(img) {

        if (!img)
            return "images/log.png"
        // if (img.includes('olx-st/_1_.jpg')) {
        //   return "images/log.png"
        // }
        else
            return img

    }

    render() {

        const property = this.state.property;
        return (


            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6" >
                <Link to={{
                    pathname: "/" + property.property_id,
                    property: property
                }}
                >

                    <div className="article">
                        <div className="image"><img
                            src={this.onImage(property.details.images[0])}
                        /></div>
                        <div className="article-category">
                            <label className="text-green" style={{ "fontWeight": "bold" }}>{property.details.price}</label></div>
                        <h3 className="osLight arabic-english text-green">
                            <div>{property.details.title}</div >
                        </h3>
                        <p className="arabic-english">{property.details.description}</p>

                        <div className="footer">
                            <div className="singleTop">
                                <ul className="features">
                                    <FeatureFooter property={property}></FeatureFooter>
                                </ul>

                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        )

    }
}

export default PropertyCard;
