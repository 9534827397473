import React from 'react'
// import './App.css';
import Script from 'react-load-script'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import Loader from 'react-loader-spinner'
import LoadingOverlay from 'react-loading-overlay'
import PropertyCard from '../property/PropertyCard'
import SearchPropertyCard from '../property/SearchPropertyCard'
import $ from 'jquery'
import FacebookLoginModal from '../login/FacebookLogin'
import { userService } from '../../_services/user.service'

import { Link, Redirect, useParams, useRouteMatch } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      user: null,
      page: 1,
      hasMore: false,
      query: '',
      allProperties: [],
      sponsoredProperties: [],
      searchProperties: [],
      allPropertiesLoading: true,
      sponsoredPropertiesLoading: true,
      searching: false,
      facebookLoginModal: false
    }
    this.submit = this.submit.bind(this)
    this.search = this.search.bind(this)
    this.searchSponsored = this.searchSponsored.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.loadScript = this.loadScript.bind(this)
    this.removeBodyClass = this.removeBodyClass.bind(this)
    this.handleModalShowClick = this.handleModalShowClick.bind(this)
    this.handleModalCloseClick = this.handleModalCloseClick.bind(this)
    this.logout = this.logout.bind(this)
    this.loadMore = this.loadMore.bind(this)
  }

  removeBodyClass() {
    $('body').attr('class', 'notransition no-hidden')
  }

  loadScript(src, type) {
    const script = document.createElement('script')
    script.src = src
    script.async = false
    if (type) script.type = type
    document.body.appendChild(script)
  }

  submit(event) {
    event.preventDefault()
    this.search(true)
  }

  search(searching) {
    try {
      this.setState({ allPropertiesLoading: true }, () => {
        fetch('/endpoint/properties/find', {
          method: 'POST',
          // mode: 'CORS',
          body: JSON.stringify({ query: this.state.query }),
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(res => res.json())
          .then(data => {
            this.setState({
              allProperties: data.result,
              allPropertiesLoading: false
            })

            if (searching) {
              this.setState({
                searchProperties: data.result,
                allPropertiesLoading: false
              })
            }
          })
          .catch(e => {
            console.log(e)
            this.setState({ allPropertiesLoading: false })
          })
      })
    } catch (err) {
      this.setState({ allPropertiesLoading: false })
      console.log('Error while fetching olx data .', err)
      // return err;
      return {}
    }
  }

  searchSponsored() {
    try {
      this.setState({ sponsoredPropertiesLoading: true }, () => {
        fetch('/endpoint/properties/find/sponsored', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(res => res.json())
          .then(res => {
            this.setState({ sponsoredProperties: res.result })
            this.setState({ sponsoredPropertiesLoading: false })
          })
          .catch(e => {
            {
              console.log(e)
              this.setState({ sponsoredPropertiesLoading: false })
            }
          })
      })
    } catch (err) {
      this.setState({ sponsoredPropertiesLoading: false })
      console.log('error while fetching newgar data')

      return {}
    }
  }

  handleModalShowClick(e) {
    e.preventDefault()
    this.setState({
      facebookLoginModal: true
    })
  }

  handleModalCloseClick() {
    this.setState({
      facebookLoginModal: false
    })
  }

  logout() {
    userService.logout()
  }

  componentDidMount() {
    this.removeBodyClass()

    this.loadScript('/js/jquery-2.1.1.min.js')
    this.loadScript('/js/jquery-ui.min.js')
    this.loadScript('/js/jquery-ui-touch-punch.js')
    this.loadScript('/js/jquery.placeholder.js')
    this.loadScript('/js/bootstrap.js')
    this.loadScript('/js/jquery.touchSwipe.min.js')
    this.loadScript('/js/jquery.visible.js')
    this.loadScript('/js/blog.js', 'text/javascript')

    document.getElementById('search').onkeypress = event => {
      if (event.keyCode === 13 || event.which === 13) {
        this.props.history.push({
          pathname: '/search/',
          search: `query=${this.state.query}`
        })
      }
    }

    this.searchSponsored()
    this.loadMore()
    // this.search(false)
  }

  loadMore() {
    console.log('loading more ...')
    try {
      fetch(`/endpoint/properties/find?page=${this.state.page}`, {
        method: 'POST',
        // mode: 'CORS',
        body: JSON.stringify({ query: this.state.query }),
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => res.json())
        .then(data => {
          console.log(data.total_pages == this.state.page)
          this.setState({
            allProperties: this.state.allProperties.concat(data.result),
            hasMore: this.state.page != data.total_pages,
            allPropertiesLoading: false,
            page: this.state.page + 1
          })
        })
        .catch(e => {
          console.log(e)
          this.setState({ allPropertiesLoading: false })
        })
    } catch (err) {
      this.setState({ allPropertiesLoading: false })
      console.error('Error while fetching olx data .', err)
      // return err;
      return {}
    }
  }

  handleChange(event) {
    const { name, value } = event.target
    this.setState({
      [name]: value
    })
  }



  render() {
    const {
      allProperties,
      sponsoredProperties,
      allPropertiesLoading,
      sponsoredPropertiesLoading,
      facebookLoginModal
    } = this.state

    const numberOfImages = 11

    return (
      <div>
        <div className='blog-search active'>
          <input
            id='search'
            type='search'
            placeholder=' ... ابحث بالكود, الوصف '
            className='osLight english'
            name='query'
            onChange={this.handleChange}
          />
        </div>

        <div id='hero-container-blog'>
          <LoadingOverlay
            active={this.state.searching}
            spinner
            text='Loading your content...'
          >
            {facebookLoginModal ? (
              <FacebookLoginModal
                handleModalCloseClick={this.handleModalCloseClick}
              />
            ) : null}
            <div id='carouselBlog' className='carousel slide featured' data-ride='carousel'>
              <ol className='carousel-indicators'>
                {[...Array(numberOfImages)].map((_, index) => (
                  <li
                    key={index+1}
                    data-target='#carouselBlog'
                    data-slide-to={index+2}
                    className={index === 0 ? 'active' : ''}
                  ></li>
                ))}
              </ol>
              <div className='carousel-inner'>
                {[...Array(numberOfImages)].map((_, index) => (
                  <div
                    className={`item ${index === 0 ? 'active' : ''}`}
                    key={index+1}
                    style={{ backgroundImage: `url(images/${(index + 1).toString().padStart(3, '0')}.jpeg)` }}
                  ></div>
                ))}
              </div>
              <a className='left carousel-control' href='#carouselBlog' role='button' data-slide='prev'>
                <span className='fa fa-chevron-left'></span>
              </a>
              <a className='right carousel-control' href='#carouselBlog' role='button' data-slide='next'>
                <span className='fa fa-chevron-right'></span>
              </a>
            </div>

            <div className='home-header'>
              <div className='home-logo osLight hidden'>
                {' '}
                <a href='https://www.newgar.com' style={{ color: 'white' }}>
                  <strong className='arabic-english'> نيوجار</strong>
                </a>
              </div>
              <a href='#' className='home-navHandler visible-xs'>
                <span className='fa fa-bars'></span>
              </a>
              <a href='#' className='toggle-search'>
                <span className='icon-magnifier'></span>
              </a>
              <div className='blog-nav'>
                <ul>
                  <li>
                    <a
                      className='arabic'
                      style={{ borderRadius: '25px' }}
                      href='https://elitereals.com/TOS'
                    >
                      <span className='arabic'>شروط الاستخدام </span>
                    </a>
                  </li>

                  {/* <li><a href="https://www.facebook.com/newgarsite/" className="arabic">تواصل معنا</a></li>
                  <li><a href="https://www.newgar.com" className="arabic">المزيد</a></li> */}

                  {userService.currentUserValue ? (
                    <>
                      <li>
                        <a
                          href='#'
                          className='arabic'
                          style={{ borderRadius: '25px' }}
                          onClick={userService.logout}
                        >
                          خروج
                        </a>
                      </li>

                      {/* <li> {userService.currentUserValue.name} </li> */}

                      <li>
                        <Link
                          to={{
                            pathname: '/properties/add'
                          }}
                          className='arabic'
                          style={{ borderRadius: '25px' }}
                        >
                          اعلان جديد
                        </Link>
                      </li>

                      <li>
                        {/* /${userService.currentUserValue.facebook_id} */}
                        <Link
                          to={{
                            pathname: `/users/profile/`
                          }}
                          className='arabic'
                          style={{ borderRadius: '25px' }}
                        >
                          حسابي
                        </Link>
                      </li>
                    </>
                  ) : (
                    <li>
                      <a
                        className='arabic'
                        style={{ borderRadius: '25px' }}
                        onClick={this.handleModalShowClick}
                      >
                        دخول
                      </a>
                      {/* https://.com | localhost*/}
                      <a
                        className='arabic'
                        style={{ borderRadius: '25px' }}
                        href='https://elitereals.com/endpoint/auth/facebook'
                      >
                        <span className='arabic hidden'>دخول بواسطة الفيسبوك </span>
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </LoadingOverlay>
        </div>

        <div className='blog-content'>
          <div className='home-wrapper'>
            <div className='row'>
              <div className='col-xs-12 col-sm-12 col-md-9 col-lg-9'>
                <h2 className='osLight arabic' style={{ textAlign: 'right' }}>
                  {' '}
                  عقارات نيوجار المميزة{' '}
                </h2>

                {sponsoredPropertiesLoading ? (
                  <div className='row'>
                    <div className='col-md-offset-5 col-xs-offset-5'>
                      <Loader
                        type='TailSpin'
                        color='#0eaaa6'
                        height={100}
                        width={100}
                      />
                    </div>
                  </div>
                ) : (
                  <div className='row'>
                    {sponsoredProperties &&
                      sponsoredProperties.length >= 1 &&
                      sponsoredProperties.map((property, index) => (
                        // <PropertyCard key={index} property={property}></PropertyCard>
                        <SearchPropertyCard
                          key={property.property_id}
                          property={property}
                        ></SearchPropertyCard>
                      ))}
                  </div>
                )}
              </div>
            </div>

            <div className='row'>
              <div className='col-xs-12 col-sm-12 col-md-9 col-lg-9'>
                <h2 className='osLight arabic' style={{ textAlign: 'right' }}>
                  العقارات المعروضة
                </h2>

                {
                  <div className='row'>
                    <InfiniteScroll
                      dataLength={this.state.allProperties.length}
                      next={this.loadMore}
                      hasMore={true}
                      loader={
                        <div className='row'>
                          <div className='col-md-offset-5 col-xs-offset-5'>
                            <Loader
                              type='TailSpin'
                              color='#0eaaa6'
                              height={100}
                              width={100}
                            />
                          </div>
                        </div>
                      }
                    >
                      {allProperties.map((property, index) => (
                        <SearchPropertyCard
                          key={property.property_id}
                          property={property}
                        ></SearchPropertyCard>
                      ))}
                    </InfiniteScroll>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>

        <div className='row text-center'></div>
      </div>
    )
  }
}

export default Home
