import React, {
} from "react";

import AppleLogin from 'react-apple-login'


import { userService } from '../../_services/user.service'



class FacebookLoginModal extends React.Component {
    constructor(props) {
        super(props);
        this.handleCloseClick = this.handleCloseClick.bind(this);
        this.loadScript = this.loadScript.bind(this);
        this.loginWithFB = this.loginWithFB.bind(this);
    }

    loadScript(src) {
        const script = document.createElement("script");
        script.src = src;
        script.async = false;
        document.body.appendChild(script);
    }


    async loginWithFB() {

        let user = await userService.login()

    }

    componentDidMount() {
        const { handleModalCloseClick } = this.props;
        window.$(this.modal).modal('show');
        window.$(this.modal).on('hidden.bs.modal', handleModalCloseClick);



    }
    handleCloseClick() {
        const { handleModalCloseClick } = this.props;
        window.$(this.modal).modal('hide');
        handleModalCloseClick();
    }
    render() {
        return (
            <div>
                <div className="modal fade" ref={modal => this.modal = modal} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-sm" role="document">
                        <div className="signLogo osLight"><span className="fa fa-home"></span> Elite Reals </div>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title arabic" id="exampleModalLabel">دخول</h5>
                                <button type="button" className="hidden close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form role="form">
                                    <div className="form-group">
                                        <div className="btn-group-justified">
                                            <a
                                                href='https://elitereals.com/endpoint/auth/facebook'
                                                className="btn btn-lg btn-facebook arabic"><span className="fab fa-facebook-f pull-left"></span>دخول بواسطة الفيس بوك</a>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className="btn-group-justified">
                                            <a
                                                href='https://elitereals.com/endpoint/auth/google'
                                                className="btn btn-lg btn-google arabic"><span className="fab fa-google pull-left"></span>دخول بواسطة جوجل</a>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className="btn-group-justified">
                                            <a
                                                href='https://elitereals.com/endpoint/auth/apple'
                                                className="btn btn-lg btn-apple arabic"><span className="fab fa-apple pull-left"></span>دخول بواسطة ابل</a>
                                        </div>
                                    </div>
                                    <div className="hidden">
                                        <div className="signOr arabic">أو</div>
                                        <div className="form-group">
                                            <input type="text" placeholder="Email Address" className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <input type="password" placeholder="Password" className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-xs-6">
                                                    <div className="checkbox custom-checkbox">
                                                        <label>
                                                            <input type="checkbox" />
                                                            <span className="fa fa-check"></span> Remember me
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-xs-6 align-right">
                                                    <p className="help-block"><a href="#" className="text-green">Forgot password?</a></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="btn-group-justified">
                                                <a href="explore.html" className="btn btn-lg btn-green">Sign In</a>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="help-block hidden">Don't have an account? <a href="signup.html" className="text-green">Sign Up</a></p>
                                </form>
                            </div>
                        </div>
                        <div className="signFooter">Elite Reals<br />© 2023</div>
                    </div>
                </div>
            </div>



        );
    }
}


export default FacebookLoginModal;