import React, {
} from "react";
import { Formik, Field, Form, ErrorMessage, getIn } from 'formik';
import * as Yup from 'yup';
import { userService } from '../../_services';
import Loader from 'react-loader-spinner'


class ProfileForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            details: '',
            isLoading: false

        }

    }

    componentDidMount() {

        this.setState({ details: this.props.details }, () => { console.log(this.state) })
    }

    render() {

        const { isLoading, details } = this.state
        return (
            <div>

                <Formik
                    initialValues={{ details }}
                    enableReinitialize={true}
                    validationSchema={
                        Yup.object().shape({
                            details: Yup.object().shape({
                                // email: Yup.string().required(' '),
                                phone_number: Yup.string().matches(/^(01)\d{9}$/, 'برجاء ادخال رقم صحيح').required(' ')
                            })
                        })
                    }

                    onSubmit={(values, { setSubmitting }) => {

                        this.setState(
                            { isLoading: true },
                            async () => {
                                try {
                                    // console.log(values.details.phone_number)
                                    await userService.completeProfile({ phone_number: values.details.phone_number })
                                    this.setState({ isLoading: false })
                                    this.props.disableEditProfile()

                                } catch (e) {
                                    console.log(e)
                                }



                            }
                        )

                    }
                    }

                    render={({ values, errors, status, touched, isSubmitting, }) => (
                        <Form>
                            <div className="form-horizontal">

                                <div className={
                                    getIn(errors, '{details}.phone_number') && getIn(touched, 'details.phone_number') ? 'form-group has-error' : 'form-group'
                                } >
                                    <label className="col-sm-2 control-label"><strong>Phone</strong></label>
                                    <div className="col-sm-10">
                                        <Field
                                            name="details.phone_number"
                                            className="form-control align-right"
                                            type="text"
                                            placeholder="phone number" />
                                    </div>
                                    <ErrorMessage name="details.phone_number" component="div" className="help-block error" />

                                </div>


                                <div className={
                                    getIn(errors, 'details.email') && getIn(touched, 'details.email') ? 'form-group has-error' : 'form-group hidden'
                                } >
                                    <label className="col-sm-2 control-label"><strong>Email</strong></label>
                                    <div className="col-sm-10">
                                        <Field
                                            name="details.email"
                                            className="form-control align-right"
                                            type="text"
                                            placeholder="Email" />
                                    </div>
                                    <ErrorMessage name="details.email" component="div" className="help-block error" />

                                </div>
                                {this.state.isLoading ? <Loader
                                    type="TailSpin"
                                    color="#0eaaa6"
                                    height={50}
                                    width={50}
                                /> : <button
                                    id="submit-profile"
                                    type='submit'
                                    className="btn btn-xs btn-round btn-o btn-green"
                                >Update</button>}
                            </div>
                        </Form>


                    )

                    }
                />

            </div>

        );
    }
}


export default ProfileForm;