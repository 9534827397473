import React from 'react';
// import './App.css';
import Script from 'react-load-script'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'

import $ from 'jquery';

import {
  Link

} from "react-router-dom";


class LeftSide extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      property: null
    }
    this.removeBodyClass = this.removeBodyClass.bind(this);

  }
  removeBodyClass() {
    $("body").attr("class", "notransition");
  }
  componentDidMount() {
    this.removeBodyClass();
  }
  render() {

    return (
      < div >
        < div id="leftSide" >
          <nav className="leftNav scrollable">
            <div className="search hidden">
              <span className="searchIcon icon-magnifier"></span>
              <input type="text" placeholder="Search for houses, apartments..." />
              <div className="clearfix"></div>
            </div>
            <ul>
              <li><a href="/"><span className="navIcon icon-compass"></span><span className="navLabel">اكتشف</span></a></li>
              <li><a href="https://www.newgar.com"><span className="navIcon icon-plus"></span><span className="navLabel">اضافة</span></a></li>


            </ul>
          </nav>

        </div >
        <div className="closeLeftSide"></div>
      </div >
    )

  }
}



export default LeftSide;
