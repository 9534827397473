import React from 'react';
import $ from 'jquery';
import SearchPropertyCard from '../property/SearchPropertyCard'
import FilterProperty from './FilterProperty'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import queryString from 'query-string'

class Search extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            propertiesPool: null,
            properties: null,
            searching: true,
            filter: null,


        }
        this.loadScript = this.loadScript.bind(this);
        this.removeBodyClass = this.removeBodyClass.bind(this);
        this.updateFilter = this.updateFilter.bind(this);
        this.filterProps = this.filterProps.bind(this);

    }

    loadScript(src) {
        const script = document.createElement("script");
        script.src = src;
        script.async = false;
        document.body.appendChild(script);
    }

    removeBodyClass() {
        $("body").attr("class", "notransition");
    }


    updateFilter(filter) {
        this.setState({
            filter: filter,

        }, () => {
            let filteredProps = this.state.propertiesPool.map(this.filterProps).filter(x => x)
            this.setState({ properties: filteredProps })

        })
    }

    filterProps(property, index, array) {
        try {
            
            const { properties, searching, filter } = this.state
            let price = parseInt(property.details.price);
            let area = parseInt(property.details.area);
            let room = parseInt(property.details.room);
            let bathroom = parseInt(property.details.bathroom);
            
            

            if (price >= filter.price[0] &&
                price <= filter.price[1] &&
                area >= filter.area[0] &&
                area <= filter.area[1] &&
                (property.details.purpose == filter.purpose  | true) &&
                room == filter.room &&
                bathroom == filter.bathroom

            ) {
                console.log('found match', property.property_id)
                return property
            }
            else{
                console.log(`property ${property.property_id} didn't match`)
            }

        }
        catch (exception) {
           
            console.log(property.property_id, ' threw exception', exception)
             return null;
        }

    }


    componentDidMount() {

        this.removeBodyClass();
        this.loadScript('/js/jquery-2.1.1.min.js');
        this.loadScript('/js/jquery-ui.min.js');
        this.loadScript('/js/jquery-ui-touch-punch.js');
        this.loadScript('/js/jquery.placeholder.js');
        this.loadScript('/js/bootstrap.js');
        this.loadScript('/js/jquery.touchSwipe.min.js');
        this.loadScript('/js/jquery.slimscroll.min.js');
        this.loadScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyBJ7TJYNJpabUQj2f9uEcRgtDjYWKwuPE4&amp;libraries=geometry&amp;libraries=places');
        this.loadScript('/js/infobox.js');
        this.loadScript('/js/jquery.tagsinput.min.js');
        this.loadScript('/js/bootstrap-datepicker.js');
        this.loadScript('/js/app.js');

         window.properties = []
        let params = queryString.parse(this.props.location.search)
        console.log("query was ", params)
        fetch('/endpoint/properties/find/', {
            method: 'POST',
            body: JSON.stringify({ "query": params.query }),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then((data) => {
                this.setState({
                    propertiesPool: data.result,
                    properties: data.result,
                    searching: false
                }, () => { 
                   
                       window.properties =  window.properties.concat( data.result)
                 })
            })

    }
    render() {

        const { properties, searching } = this.state;

        return (
            <div>
                <div id="mapView" className="mob-min"><div className="mapPlaceholder"><span className="fa fa-spin fa-spinner"></span> Loading map...</div></div>
                <div id="content" className="mob-max">
                    {properties ?
                        <div>
                            {/* FILTER */}
                            <FilterProperty updateFilter={(filter) => this.updateFilter(filter)}

                            ></FilterProperty>
                            {/* FILTER */}
                            {/*  */}
                            <div className="resultsList">
                                <div className="row">

                                    {properties && properties.length >= 1 &&
                                        properties.map((property, index) =>
                                            <SearchPropertyCard key={property.property_id} property={property}></SearchPropertyCard>
                                        )
                                    }
                                </div>

                                <ul className="pagination hidden">
                                    <li className="disabled"><a href="#"><span className="fa fa-angle-left"></span></a></li>
                                    <li className="active"><a href="#">1</a></li>
                                    <li><a href="#">2</a></li>
                                    <li><a href="#">3</a></li>
                                    <li><a href="#">4</a></li>
                                    <li><a href="#">5</a></li>
                                    <li><a href="#"><span className="fa fa-angle-right"></span></a></li>
                                </ul>
                            </div></div>
                        :
                        <div style={{ "display": "flex", "justifyContent": "center", "alignItems": "center", "height": "100vh" }}>


                            <Loader
                                type="TailSpin"
                                color="#0eaaa6"
                                height={100}
                                width={100}
                            />

                        </div>

                    }


                </div>
            </div>


        )

    }
}

export default Search;