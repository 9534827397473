import React, {
} from "react";
import { Link, withRouter } from 'react-router-dom';
import { userService, propertyService } from '../../_services';



class DeleteModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            property: this.props.selectedProperty
        }
        this.handleCloseClick = this.handleCloseClick.bind(this);
        this.loadScript = this.loadScript.bind(this);
    }

    loadScript(src) {
        const script = document.createElement("script");
        script.src = src;
        script.async = false;
        document.body.appendChild(script);
    }



    componentDidMount() {
        const { handleModalCloseClick } = this.props;
        window.$(this.modal).modal('show');
        window.$(this.modal).on('hidden.bs.modal', handleModalCloseClick);



    }
    handleCloseClick() {
        const { handleModalCloseClick } = this.props;
        window.$(this.modal).modal('hide');
        handleModalCloseClick();
    }


    remove(property) {
        propertyService.remove(property);
        this.handleCloseClick();
        window.location.reload();
    }

    render() {

        const { property } = this.state

        return (
            <div>
                <div className="modal fade" ref={modal => this.modal = modal} id="exampleModal2" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-sm" role="document">

                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title arabic hidden" id="exampleModalLabel">مسح الاعلان</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">

                                <div className="form-group">
                                    <div className="btn-group-justified">
                                        Are you sure you want to delete {property.details.title} ?

                                    </div>
                                </div>


                            </div>
                            <div className="modal-footer">

                                <button id="cancel" type="button" className="btn btn-default arabic-english" data-dismiss="modal">
                                    غلق</button>

                                <a
                                    className=" btn btn-danger"
                                    onClick={() => { this.remove(property) }}
                                >
                                    حذف
                                            </a>


                            </div>
                        </div>

                    </div>
                </div>
            </div >



        );
    }
}


export default withRouter(DeleteModal);