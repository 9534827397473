import React, { useState } from 'react'
import $ from 'jquery'
import SearchPropertyCard from '../property/SearchPropertyCard'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import Loader from 'react-loader-spinner'
import { userService, propertyService } from '../../_services'
import { Link, withRouter } from 'react-router-dom'
import { Button, Modal } from 'react-bootstrap'
import DeleteModal from './DeleteModal'
import ProfileForm from '../forms/profile-form'

class Profile extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      properties: null,
      isLoading: true,
      user: userService.currentUserValue,
      showDeleteModal: false,
      selectedProperty: null,
      editingProfile: false
    }
    this.loadScript = this.loadScript.bind(this)
    this.removeBodyClass = this.removeBodyClass.bind(this)
    this.handleModalShowClick = this.handleModalShowClick.bind(this)
    this.handleModalCloseClick = this.handleModalCloseClick.bind(this)
    this.editProfile = this.editProfile.bind(this)
    this.disableEditProfile = this.disableEditProfile.bind(this)
  }

  loadScript (src) {
    const script = document.createElement('script')
    script.src = src
    script.async = false
    document.body.appendChild(script)
  }

  removeBodyClass () {
    $('body').attr('class', 'notransition')
  }

  handleModalShowClick () {
    // e.preventDefault();
    this.setState({
      showDeleteModal: true
    })
  }

  handleModalCloseClick () {
    this.setState({
      showDeleteModal: false
    })
  }

  editProfile () {
    this.setState({ editingProfile: true })
  }

  disableEditProfile () {
    this.setState({ editingProfile: false })
  }

  componentDidMount () {
    this.removeBodyClass()
    this.loadScript('/js/jquery-2.1.1.min.js')
    this.loadScript('/js/jquery-ui.min.js')
    this.loadScript('/js/jquery-ui-touch-punch.js')
    this.loadScript('/js/jquery.placeholder.js')
    this.loadScript('/js/bootstrap.js')
    this.loadScript('/js/jquery.touchSwipe.min.js')
    this.loadScript('/js/jquery.slimscroll.min.js')
    this.loadScript(
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyBJ7TJYNJpabUQj2f9uEcRgtDjYWKwuPE4&libraries=geometry,places'
    )
    this.loadScript('/js/infobox.js')
    this.loadScript('/js/jquery.tagsinput.min.js')
    this.loadScript('/js/bootstrap-datepicker.js')

    userService.currentUserSubject.subscribe(user =>
      this.setState({ user: user })
    )

    const { params } = this.props.match
    window.properties = []
    console.log('loading profile page for ', this.state.user.facebook_id)
    propertyService
      .getPropertyListByFacebookId(this.state.user.facebook_id)
      .then(result => {
        // console.log('result is ', result)
        this.setState(
          {
            properties: result.result
          },
          () => {
            this.setState({
              isLoading: false
            })
            for (var key of Object.keys(this.state.properties)) {
              window.properties.push(this.state.properties[key])
            }
            this.loadScript('/js/app.js')
          }
        )
      })
      .catch(e => {
        console.log(e)
      })
  }
  render () {
    const {
      user,
      properties,
      isLoading,
      showDeleteModal,
      selectedProperty,
      editingProfile
    } = this.state
    const details = { phone_number: user.phone_number, email: user.email }

    return (
      <div>
        {showDeleteModal ? (
          <DeleteModal
            selectedProperty={this.state.selectedProperty}
            handleModalCloseClick={this.handleModalCloseClick}
          ></DeleteModal>
        ) : null}
        <div id='mapView' className='mob-min'>
          <div className='mapPlaceholder'>
            <span className='fa fa-spin fa-spinner'></span> Loading map...
          </div>
        </div>
        <div id='content' className='mob-max'>
          {!isLoading ? (
            <div>
              <div className='singleTop whiteBg'>
                <div className='row mb20'>
                  <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 pb20'>
                    <div className='row'>
                      <div className='col-xs-3'></div>
                      <div className='col-xs-6'>
                        <div className='profile-card'>
                          <div className='pc-avatar'>
                            <img
                              src={`https://graph.facebook.com/${user.facebook_id}/picture?type=normal`}
                              alt='avatar'
                            />
                          </div>
                          <div className='pc-name'>{user.name}</div>
                        </div>
                      </div>
                    </div>
                    <ul className='pc-stats'>
                      <li>
                        <span>{properties.length}</span>Property
                      </li>
                      <li>
                        <span>0</span>Likes
                      </li>
                      <li>
                        <span>0/5</span>Rating
                      </li>
                    </ul>
                    <div className='clearfix'></div>
                  </div>
                  <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 pb20'>
                    <div className='pc-title osLight'>Contact info</div>

                    {editingProfile ? (
                      <ProfileForm
                        details={details}
                        disableEditProfile={this.disableEditProfile}
                      />
                    ) : (
                      <div>
                        <div className='row pb10'>
                          <div className='col-xs-4'>
                            <strong>Phone</strong>
                          </div>
                          <div className='col-xs-8 align-right'>
                            {user.phone_number}
                          </div>
                        </div>

                        <div className='row pb10'>
                          <div className='col-xs-4'>
                            <strong>Email</strong>
                          </div>
                          <div className='col-xs-8 align-right'>
                            <a href='mailto:#' className='text-green'>
                              {user.email}
                            </a>
                          </div>
                        </div>

                        <span
                          className='btn btn-xs btn-round btn-o btn-green dropdown-toggle'
                          onClick={this.editProfile}
                        >
                          Edit
                        </span>
                      </div>
                    )}

                    <div className='pc-social hidden'>
                      <a href='#' className='btn btn-icon btn-facebook'>
                        <span className='fab fa-facebook-f'></span>
                      </a>
                      <a href='#' className='btn btn-icon btn-twitter'>
                        <span className='fab fa-twitter'></span>
                      </a>
                      <a href='#' className='btn btn-icon btn-google'>
                        <span className='fab fa-google-plus'></span>
                      </a>
                      <a href='#' className='btn btn-icon btn-pinterest'>
                        <span className='fab fa-pinterest'></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className='table-overflow'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th className='arabic-english'> اعلان</th>
                      <th className='arabic-english'>التاريخ</th>
                      <th className='arabic-english'>الحالة</th>
                      <th className='arabic-english'>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody className='table'>
                    {properties.map((ad, idx) => (
                      <tr key={idx}>
                        <td>
                          <span className='userName'>{ad.details.title}</span>
                        </td>
                        <td>
                          {
                            new Date(ad.details.create_date)
                              .toLocaleString()
                              .split(',')[0]
                          }
                        </td>
                        <td>
                          <span
                            className={
                              ad.details.status === 'active'
                                ? 'label label-success'
                                : ad.details.status === 'review'
                                ? 'label label-warning'
                                : ad.details.status === 'suspended' || 'deleted'
                                ? 'label label-danger'
                                : ''
                            }
                          >
                            {ad.details.status}
                          </span>
                        </td>
                        <td>
                          <div className='btn-group'>
                            <Link
                              to={{
                                pathname: `/properties/${ad.property_id}/update`
                              }}
                              replace
                              className='btn btn-xs btn-round btn-o btn-green dropdown-toggle'
                            >
                              <span className='fas fa-pencil-alt hidden'></span>
                              Edit
                            </Link>
                          </div>
                        </td>
                        <td>
                          <div className='btn-group'>
                            <a
                              className='btn btn-xs btn-round btn-o btn-red dropdown-toggle'
                              onClick={() => {
                                this.setState({ selectedProperty: ad }, () => {
                                  this.handleModalShowClick()
                                })
                              }}
                            >
                              <span className='fas fa-times hidden'></span>
                              Delete
                            </a>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh'
              }}
            >
              <Loader
                type='TailSpin'
                color='#0eaaa6'
                height={100}
                width={100}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default withRouter(Profile)
