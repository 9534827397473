
import { authHeader, handleResponse } from '../_helpers';
import { BehaviorSubject } from 'rxjs';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';


console.log('calling user.service script')

const currentUserSubject = new BehaviorSubject();

loadUserInfo();



export const userService = {
    loadUserInfo,
    completeProfile,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() { return currentUserSubject.value },
    currentUserSubject: currentUserSubject
};



async function completeProfile(data) {
    let header = authHeader()
    header['Content-Type'] = 'application/json'
    const requestOptions = {
        method: 'POST',
        headers: header,
        body: JSON.stringify(data),
        redirect: 'follow'
    };

    return fetch('/endpoint/auth/complete-profile', requestOptions)
        .then(handleResponse)
        .then(function (responseJson) {
            //update user globally
            loadUserInfo()
            return responseJson
        })

}

function loadUserInfo() {
    let token = Cookies.get('token')
    if (token) {
        console.log('setting user in userservice')
        var decoded = jwt_decode(token);
        let user = decoded.user
        localStorage.setItem('currentUser', JSON.parse(user));
        currentUserSubject.next(JSON.parse(user));
    }
    else {
        currentUserSubject.next(null)
    }
}


function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    Cookies.remove('token')
    currentUserSubject.next(null);

}