import { authHeader, handleResponse } from '../_helpers'

export const propertyService = {
  add,
  remove,
  getPropertyListByFacebookId,
  getPropertyById,
  incView
}

async function add (form) {
  for (var pair of form.entries()) {
    // console.log(pair[0]+ ' - ' + pair[1]);
  }
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: form
  }

  return fetch('/endpoint/properties', requestOptions)
    .then(handleResponse)
    .then(data => {
      return data
    })
}

async function remove (property) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  }
  return fetch(`/endpoint/properties/${property.property_id}`, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data
    })
}

function getPropertyById (property_id) {
  return new Promise(resolve => {
    fetch(`/endpoint/properties/${property_id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => {
        // console.log('property.service received', data)
        resolve(data)
      })
  })
}

function getPropertyListByFacebookId (facebook_id) {
  return new Promise(resolve => {
    fetch(`/endpoint/users/${facebook_id}/properties`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => {
      res
        .json()
        .then(data => {
          // console.log('property.service received', data)
          resolve(data)
        })
        .catch(e => {
          console.log(e)
        })
    })
  })
}

/**
 * @deprecated replaced by user/:id/properties
 */
function getPropertyListByFB_Id (facebook_id) {
  return new Promise(resolve => {
    fetch(`/endpoint/property/find/user/${facebook_id}`, {
      method: 'GET',
      // body: JSON.stringify({ facebook_id: facebook_id }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => {
        // console.log('property.service received', data)
        resolve(data)
      })
  })
}

async function incView (propertyId) {
  fetch(`/endpoint/properties/${propertyId}/views?source=web`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(res => res.json())
    .then(data => {
      return data
    })
}
