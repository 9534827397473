import React from 'react';


class FilterProperty extends React.Component {

    constructor(props) {
        super(props)
        this.state = {

            purpose: 'البيع',
            price: null,
            area: null,
            room: 0,
            bathroom: 0


        }

        this.loadScript = this.loadScript.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.filter = this.filter.bind(this);
    }

    handleChange(e) {

        console.log(e.target.name, e.target.value)
        this.setState({ [e.target.name]: e.target.value });

    }

    filter() {
        let price = window.$('.priceSlider').slider("values");
        let area = window.$('.areaSlider').slider("values");
        let room = window.$('#room').val();
        let bathroom = window.$('#bathroom').val();

        this.setState({
            ...this.state,
            room: room,
            bathroom: bathroom,
            price: price,
            area: area
        }, () => {
            console.log('filterProperty ', this.state);
            this.props.updateFilter(this.state)
        })
    }


    loadScript(src) {
        const script = document.createElement("script");
        script.src = src;
        script.async = false;
        document.body.appendChild(script);
    }

    componentDidMount() {

        this.loadScript('/js/jquery-2.1.1.min.js');
        this.loadScript('/js/jquery-ui.min.js');
        this.loadScript('/js/jquery-ui-touch-punch.js');
        this.loadScript('/js/jquery.placeholder.js');
        this.loadScript('/js/bootstrap.js');
        this.loadScript('/js/jquery.touchSwipe.min.js');
        this.loadScript('/js/jquery.slimscroll.min.js');
        this.loadScript('/js/infobox.js');
        this.loadScript('/js/jquery.tagsinput.min.js');
        this.loadScript('/js/bootstrap-datepicker.js');
        this.loadScript('/js/app.js');


    }

    render() {
        return (

            <div className="filter">
                <h1 className="osLight arabic-english">ترشيح البحث</h1>
                <a href="#" className="handleFilter"><span className="icon-equalizer"></span></a>
                <div className="clearfix"></div>
                <div className="filterForm">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 formItem">
                            <div className="formField">
                                <label className="arabic">الغرض</label>


                                <select
                                    name="purpose"
                                    className="btn btn-default" style={{ "width": "100%" }}
                                    value={this.state.selectValue}
                                    onChange={this.handleChange}
                                >
                                    {/* <option value="الايجار/البيع" className="arabic">الايجار/البيع</option> */}
                                    <option value="الايجار" className="arabic">الايجار</option>
                                    <option value="البيع" className="arabic">البيع</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 formItem">
                            <div className="formField">
                                <label className="arabic">نطاق السعر</label>
                                <div className="slider priceSlider" >
                                    <div className="sliderTooltip">
                                        <div className="stArrow"></div>
                                        <div className="stLabel"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 formItem">
                            <div className="formField">
                                <label className="arabic">نطاق المساحة</label>
                                <div className="slider areaSlider" >
                                    <div className="sliderTooltip">
                                        <div className="stArrow"></div>
                                        <div className="stLabel"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-inline ">
                            <div className="col-xs-6 col-sm-6 col-md-3 col-lg-3 formItem">
                                <div className="form-group">
                                    <div className="formField ">
                                        <label className="arabic">غرف النوم</label>
                                        <div className="volume">
                                            <a href="#" className="btn btn-gray btn-round-left"><span className="fa fa-angle-left"></span></a>
                                            <input
                                                id="room"
                                                name="room"
                                                onChange={this.handleChange}
                                                type="text" className="form-control" defaultValue="1" />
                                            <a href="#" className="btn btn-gray btn-round-right"><span className="fa fa-angle-right"></span></a>
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-xs-6 col-sm-6 col-md-3 col-lg-3 formItem">
                                <div className="form-group">
                                    <div className="formField ">
                                        <label className="arabic">الحمام</label>
                                        <div className="volume">
                                            <a href="#" className="btn btn-gray btn-round-left"><span className="fa fa-angle-left"></span></a>
                                            <input
                                                id="bathroom"
                                                name="bathroom"
                                                onChange={this.handleChange} type="text" className="form-control" defaultValue="1" />
                                            <a href="#" className="btn btn-gray btn-round-right"><span className="fa fa-angle-right"></span></a>
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-md-offset-2  formItem">
                                <div className="form-group">
                                    <div className="formField">
                                        <label className="arabic"> ترشيح</label>
                                        <button className="btn btn-green btn-round arabic" onClick={this.filter}>بحث</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FilterProperty;
