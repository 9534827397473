import React from 'react';
import $ from 'jquery';
class SearchFeatureFooter extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            property: this.props.property
        }

        this.convertNumbers2English = this.convertNumbers2English.bind(this);
    }

    convertNumbers2English(string) {
        var arabic = /[\u0600-\u06FF]/;
        if (arabic.test(string)) {
            return string.replace(/[\u0660-\u0669]/g, function (c) {
                return c.charCodeAt(0) - 0x0660;
            }).replace(/[\u06f0-\u06f9]/g, function (c) {
                return c.charCodeAt(0) - 0x06f0;
            });
        }
        else return string

    }

    render() {

        const area = 'area';
        const bedRoom = 'room';
        const bathRoom = 'bathroom';

        const details = this.state.property.details
        return (
            <>
                <li >
                    <span className="ficon icon-bathtub3" /> {(details.bathroom)}

                </li>


                <li >
                    <span className="fa fa-bed" /> {(details.room)}

                </li>

                <li >
                    <span className="icon-frame" /> {(details.area)} متر

                </li>
            </>
        )

    }
}

export default SearchFeatureFooter;
