import React from 'react'
import { Link, Redirect } from 'react-router-dom'
class Amenities extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      features:
        this.props.features |
        {
          furnished: false,
          air_conditioner: false,
          telephone: false,
          electricity: false,
          water_heater: false,
          garden: false,
          water: false,
          internet: false,
          security: false,
          gas: false,
          pool: false
        }
    }
  }

  componentDidMount () {
    console.log('features are', this.props.features)
  }

  render () {
    const features = this.state.features
    return (
      <div className='amenities'>
        <h3 className='Amenities '>المميزات</h3>
        <div className='row'>
          <span
            className={`col-xs-6 col-sm-6 col-md-4 col-lg-4 amItem arabic-english ${
              features.furnished ? 'active' : 'inactive'
            }`}
          >
            <span className='fa fa-home'></span>مفروش
          </span>

          <span
            className={`col-xs-6 col-sm-6 col-md-4 col-lg-4 amItem arabic-english ${
              features.air_conditioner ? 'active' : 'inactive'
            }`}
          >
            <span className='fa fa-asterisk'></span> تكييف
          </span>
          <span
            className={`col-xs-6 col-sm-6 col-md-4 col-lg-4 amItem arabic-english ${
              features.telephone ? 'active' : 'inactive'
            }`}
          >
            <span className='fa fa-phone'></span> التليفون
          </span>
          <span
            className={`col-xs-6 col-sm-6 col-md-4 col-lg-4 amItem arabic-english ${
              features.electricity ? 'active' : 'inactive'
            }`}
          >
            <span className='fa fa-bolt'></span>كهرباء
          </span>
          <span
            className={`col-xs-6 col-sm-6 col-md-4 col-lg-4 amItem arabic-english ${
              features.water_heater ? 'active' : 'inactive'
            }`}
          >
            <span className='fas fa-fire'></span> سخان
          </span>
          <span
            className={`col-xs-6 col-sm-6 col-md-4 col-lg-4 amItem arabic-english ${
              features.garden ? 'active' : 'inactive'
            }`}
          >
            <span className='fa fa-leaf'></span> حديقة
          </span>
          <span
            className={`col-xs-6 col-sm-6 col-md-4 col-lg-4 amItem arabic-english ${
              features.water ? 'active' : 'inactive'
            }`}
          >
            <span className='fa fa-tint'></span> مياه
          </span>
          <span
            className={`col-xs-6 col-sm-6 col-md-4 col-lg-4 amItem arabic-english ${
              features.internet ? 'active' : 'inactive'
            }`}
          >
            <span className='fa fa-wifi'></span> انترنت
          </span>
          <span
            className={`col-xs-6 col-sm-6 col-md-4 col-lg-4 amItem arabic-english ${
              features.security ? 'active' : 'inactive'
            }`}
          >
            <span className='fas fa-shield-alt'></span> حراسة
          </span>
          <span
            className={`col-xs-6 col-sm-6 col-md-4 col-lg-4 amItem arabic-english ${
              features.gas ? 'active' : 'inactive'
            }`}
          >
            <span className='fab fa-gripfire'></span> غاز
          </span>
          <span
            className={`col-xs-6 col-sm-6 col-md-4 col-lg-4 amItem arabic-english ${
              features.pool ? 'active' : 'inactive'
            }`}
          >
            <span className='fas fa-swimming-pool'></span> حمام سباحة
          </span>
        </div>
      </div>
    )
  }
}

export default Amenities
