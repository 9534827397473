import { userService } from '../_services';
import Cookies from 'js-cookie';


export function authHeader() {
    // return authorization header with jwt token
    let token = Cookies.get('token')
    
    if (token) {
        console.log('found token',token)
        return { Authorization: `Bearer ${token}` };
    } else {
        return {};
    }
}