import React from 'react';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import FeatureFooter from './FeatureFooter'
import Amenities from './Amenities'
import $ from 'jquery';
import ContactModal from './ContactModal';
import { propertyService } from '../../_services';



class PropertyTemplate extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            property: null,
            showContactModal: false,

        }
        this.loadScript = this.loadScript.bind(this);
        this.removeBodyClass = this.removeBodyClass.bind(this);
        this.handleModalShowClick = this.handleModalShowClick.bind(this);
        this.handleModalCloseClick = this.handleModalCloseClick.bind(this);


    }

    loadScript(src) {
        const script = document.createElement("script");
        script.src = src;
        script.async = false;
        document.body.appendChild(script);
    }
    removeBodyClass() {
        $("body").attr("class", "notransition");
    }

    handleModalShowClick(e) {
        e.preventDefault();
        this.setState({
            showContactModal: true
        })
    }

    handleModalCloseClick() {
        this.setState({
            showContactModal: false
        })
    }

    componentWillMount() {

    }


    componentDidMount() {

        this.removeBodyClass();
        this.loadScript('/js/jquery-2.1.1.min.js');
        this.loadScript('/js/jquery-ui.min.js');
        this.loadScript('/js/jquery-ui-touch-punch.js');
        this.loadScript('/js/jquery.placeholder.js');
        this.loadScript('/js/bootstrap.js');
        this.loadScript('/js/jquery.touchSwipe.min.js');
        this.loadScript('/js/jquery.slimscroll.min.js');
        this.loadScript('/js/jquery.visible.js');
        this.loadScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyBJ7TJYNJpabUQj2f9uEcRgtDjYWKwuPE4&amp;libraries=geometry&amp;libraries=places');
        this.loadScript('/js/infobox.js');
        this.loadScript('/js/jquery.tagsinput.min.js');
        this.loadScript('/js/bootstrap-datepicker.js');
        this.loadScript('/js/app.js');


        window.properties = []

        const { params } = this.props.match;
        //load property directly from database
        if (!this.props.location.property) {
            fetch(`/endpoint/properties/${params.id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => res.json())
                .then((data) => {
                    // console.log(data)
                    this.setState({ property: data.result }, () => {
                        window.properties.push(this.state.property)
                        propertyService.incView(this.state.property.property_id)
                    });
                })

        }
        //loadproperty passed (used in Home screen)
        else {
            this.setState({ property: this.props.location.property }, () => {
                propertyService.incView(this.state.property.property_id)
                window.properties.push(this.state.property)
            })
        }

    }


    render() {
        const { property, showContactModal } = this.state;
        return (
            <div>

                {property ?
                    <div>

                        {showContactModal ? <ContactModal user={property.user} handleModalCloseClick={this.handleModalCloseClick}></ContactModal> : null}

                        <div id="mapView" className="mob-min"><div className="mapPlaceholder"><span className="fa fa-spin fa-spinner"></span> Loading map...</div></div>



                        <div id="content" className="mob-max">
                            <div className="singleTop">
                                <div id="carouselFull" className="carousel slide" data-ride="carousel">

                                    <ol className="carousel-indicators">
                                        <li data-target="#carouselFull" data-slide-to="0" className="active"></li>

                                        {property.details.images.map((idx, image) =>
                                            <li data-target="#carouselFull" key={idx} ></li>
                                        )}

                                    </ol>

                                    <div className="carousel-inner">
                                        <div className="item active">
                                            <img src={property.details.images[0]} style={{ "height": "480px", "width": "800px" }} alt="First slide" />
                                            <div className="container">
                                                <div className="carousel-caption">

                                                </div>
                                            </div>
                                        </div>
                                        {property.details.images.map((image, idx) =>
                                            <div className="item" key={idx}>
                                                <img src={image} style={{ "height": "480px", "width": "800px" }} key={idx} />
                                                <div className="container">
                                                    <div className="carousel-caption">

                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                    <a className="left carousel-control" href="#carouselFull" role="button" data-slide="prev"><span className="fa fa-chevron-left"></span></a>
                                    <a className="right carousel-control" href="#carouselFull" role="button" data-slide="next"><span className="fa fa-chevron-right"></span></a>
                                </div>
                                <div className="summary">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                                            <div className="summaryItem">
                                                <h1 className="pageTitle"> {property.details.title} </h1>
                                                <div className="address">
                                                    <span className="icon-pointer"></span> {property.details.location.address}</div>
                                                <ul className="rating">
                                                    <li><a href="#"><span className="fa fa-star"></span></a></li>
                                                    <li><a href="#"><span className="fa fa-star"></span></a></li>
                                                    <li><a href="#"><span className="fa fa-star"></span></a></li>
                                                    <li><a href="#"><span className="fa fa-star"></span></a></li>
                                                    <li><a href="#"><span className="far fa-star"></span></a></li>
                                                    <li>(146)</li>
                                                </ul>
                                                <div className="favLink"><a href="#"><span className="icon-heart"></span></a>54</div>
                                                <ul className="stats">
                                                    <li><span className="icon-eye"></span> {property.details.views_count}</li>
                                                    {/* <li><span className="icon-bubble"></span> 13</li> */}
                                                </ul>
                                                <div className="clearfix"></div>
                                                <ul className="features">
                                                    <FeatureFooter property={property}></FeatureFooter>
                                                </ul>
                                                <div className="clearfix"></div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                            <div className="agentAvatar summaryItem">
                                                <div className="clearfix"></div>
                                                <img className="avatar agentAvatarImg" src={`https://graph.facebook.com/${property.user.facebook_id}/picture?type=normal`} alt="user" />
                                                <div className="agentName">{property.user.user_name}</div>


                                                <a data-toggle="modal" href="#contactAgent"
                                                    onClick={this.handleModalShowClick}
                                                    className="btn btn-lg btn-round btn-green contactBtn arabic">تواصل مع صاحب العقار</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                            <div className="description arabic">
                                <h3>الوصف</h3>
                                <p className="arabic-english" style={{ "textDirection": "rtl" }}>{property.details.description}</p>
                                <p className="hidden" style={{ "textDirection": "rtl" }}>للتواصل مع صاحب الاعلان مباشرة برجاء الاتصال علي   </p>
                                {/* <p>{property.user.phone_number}</p> */}
                            </div>
                            <div className="share hidden">
                                <h3>Share on Social Networks</h3>
                                <div className="row">
                                    <div className="col-xs-6 col-sm-6 col-md-3 col-lg-3 shareItem">
                                        <a href="#" className="btn btn-sm btn-round btn-o btn-facebook"><span className="fa fa-facebook"></span> Facebook</a>
                                    </div>
                                    <div className="col-xs-6 col-sm-6 col-md-3 col-lg-3 shareItem">
                                        <a href="#" className="btn btn-sm btn-round btn-o btn-twitter"><span className="fa fa-twitter"></span> Twitter</a>
                                    </div>
                                    <div className="col-xs-6 col-sm-6 col-md-3 col-lg-3 shareItem">
                                        <a href="#" className="btn btn-sm btn-round btn-o btn-google"><span className="fa fa-google-plus"></span> Google+</a>
                                    </div>
                                    <div className="col-xs-6 col-sm-6 col-md-3 col-lg-3 shareItem">
                                        <a href="#" className="btn btn-sm btn-round btn-o btn-pinterest"><span className="fa fa-pinterest"></span> Pinterest</a>
                                    </div>
                                </div>
                            </div>

                            <Amenities features={property.details.features}></Amenities>

                          

                            <div className="similar hidden">
                                <h3>Similar Properties</h3>

                            </div>
                            <div className="comments hidden">
                                <h3><span>4</span> Comments</h3>
                            </div>
                        </div>
                        <div className="clearfix"></div>



                    </div>
                    : <div style={{ "display": "flex", "justifyContent": "center", "alignItems": "center", "height": "100vh" }}>

                        <Loader
                            type="TailSpin"
                            color="#808000"
                            height={100}
                            width={100}
                        />

                    </div>
                }
            </div>)

    }
}


export default PropertyTemplate;
