import React from 'react';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import $ from 'jquery';
import { Link, withRouter } from 'react-router-dom';

class TOS extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      tos: []
    }
    this.loadScript = this.loadScript.bind(this);
    this.removeBodyClass = this.removeBodyClass.bind(this);
    this.getTos = this.getTos.bind(this)
  }

  removeBodyClass() {
    $("body").attr("class", "notransition no-hidden");
  }

  loadScript(src, type) {
    const script = document.createElement("script");
    script.src = src;
    script.async = false;
    if (type)
      script.type = type;
    document.body.appendChild(script);
  }


  componentDidMount() {
    this.removeBodyClass();
    this.loadScript('/js/jquery-2.1.1.min.js');
    this.loadScript('/js/jquery-ui.min.js');
    this.loadScript('/js/jquery-ui-touch-punch.js');
    this.loadScript('/js/jquery.placeholder.js');
    this.loadScript('/js/bootstrap.js');
    this.loadScript('/js/jquery.touchSwipe.min.js');
    this.loadScript('/js/jquery.slimscroll.min.js');
    this.loadScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyBJ7TJYNJpabUQj2f9uEcRgtDjYWKwuPE4&libraries=geometry,places');
    this.loadScript('/js/infobox.js');
    this.loadScript('/js/jquery.tagsinput.min.js');
    this.loadScript('/js/bootstrap-datepicker.js');

    this.getTos()
  }

  getTos() {
    try {
      fetch('/endpoint/tos', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => res.json())
        .then(data => {
          this.setState({ tos: data.tos })
        })
        .catch(e => {
          console.log(e)

        })

    } catch (err) {
      console.log('Error while fetching olx data .', err)
      return {}
    }
  }
  render() {

    const {
      tos
    } = this.state

    return (
      <div>
        <div className="blog-content">
          <div className="home-wrapper">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9">

                <text>  EliteReals® Terms of Service</text>
                <ul>
                  {tos.map((i) => {
                    {
                      return <li>
                        {i.text}
                      </li>
                    }
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div >
    )
  }
}



export default withRouter(TOS);;