import React, {
} from "react";
import $ from 'jquery';
import { Formik, Field, Form, ErrorMessage, getIn } from 'formik';
import * as Yup from 'yup';
import { userService, propertyService } from '../../_services';
import { Link, withRouter } from 'react-router-dom';
import Loader from 'react-loader-spinner'


class PropertyForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isUpdating: false,
            attachments: [],
            property: {
                user: userService.currentUserValue,
                source: 'newgar',
                details: {
                    title: '',
                    price: '',
                    description: '',
                    purpose: 'sale',
                    type: 'apartment',

                    room: '',
                    bathroom: '',
                    area: '',
                    location: {
                        address: '',
                        coordinates: []
                    },
                    features: {
                        furnished: false,
                        pool: false,
                        water: false,
                        electricty: false,
                        gas: false,
                        air_conditioner: false,
                        water_heater: false,
                        internet: false,
                        telephone: false,
                        garden: false,
                        Security: false
                    },
                    share: [],
                    status: 'review'
                }
            }

        }
        this.loadScript = this.loadScript.bind(this);
        this.removeBodyClass = this.removeBodyClass.bind(this);
        this.fileSelectedHandler = this.fileSelectedHandler.bind(this);
        this.walk = this.walk.bind(this);
        this.loadJavaScript = this.loadJavaScript.bind(this);

    }


    removeBodyClass() {
        $("body").attr("class", "notransition");
    }
    loadScript(src) {
        const script = document.createElement("script");
        script.src = src;
        script.async = false;
        document.body.appendChild(script);
    }

    fileSelectedHandler = (e) => {


        this.setState({
            ...this.state,
            attachments: e.target.files
        })
    }


    handleSubmit(event) {
        event.preventDefault();
    }

    //convert features from boolean to int
    async walk(obj, boolean_to_int) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
                var val = obj[key];
                if (boolean_to_int)
                    obj[key] == 1 ? obj[key] = true : obj[key] = false
                else
                    obj[key] == true ? obj[key] = 1 : obj[key] = 0
            }
        }
    }

    loadJavaScript() {
        this.removeBodyClass();
        this.loadScript('/js/jquery-2.1.1.min.js');
        this.loadScript('/js/jquery-ui.min.js');
        this.loadScript('/js/jquery-ui-touch-punch.js');
        this.loadScript('/js/jquery.placeholder.js');
        this.loadScript('/js/bootstrap.js');
        this.loadScript('/js/jquery.touchSwipe.min.js');
        this.loadScript('/js/jquery.slimscroll.min.js');
        this.loadScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyBJ7TJYNJpabUQj2f9uEcRgtDjYWKwuPE4&libraries=geometry,places&sensor=true');
        this.loadScript('/js/infobox.js');
        this.loadScript('/js/jquery.tagsinput.min.js');
        this.loadScript('/js/bootstrap-datepicker.js');
        this.loadScript('/js/fileinput.min.js')
        this.loadScript('/js/app.js');
    }


    componentDidMount() {



        let id = this.props.match.params.id
        window.properties = []
        if (id) {
            (async () => {
                let loadedProperty = await propertyService.getPropertyById(id)
                this.setState({
                    property: loadedProperty.result,
                    isUpdating: true
                }, () => {
                    window.properties.push(this.state.property)
                    this.loadJavaScript()


                })
            })()
        }
        else {
            this.loadJavaScript()
        }

    }


    render() {
        const { attachments, property } = this.state
        return (

            <div>
                <Formik
                    initialValues={property}
                    enableReinitialize={true}
                    validationSchema={
                        Yup.object().shape({
                            details: Yup.object().shape({
                                title: Yup.string().required('مطلوب'),
                                price: Yup.number().required('مطلوب'),
                                description: Yup.string().required('مطلوب'),

                                room: Yup.number().required('مطلوب'),
                                bathroom: Yup.number().required('مطلوب'),
                                area: Yup.number().required('مطلوب'),
                                // governorate: Yup.string().required('مطلوب'),
                                // city: Yup.string().required('مطلوب'),
                                location: Yup.object().shape({
                                    address: Yup.string().required('مطلوب'),
                                })
                            })
                        })

                    }

                    onSubmit={(values, { setSubmitting }) => {

                        // set longitude and latitude values
                        let lat = $('#latitude').text();
                        let long = $('#longitude').text();
                        property.details.location.coordinates[0] = Number(long)
                        property.details.location.coordinates[1] = Number(lat)
                        // remove user properties from user object
                        delete property.user.properties

                        const formData = new FormData()


                        formData.append('propertyObject', JSON.stringify(values));

                        for (var i = 0; i < this.state.attachments.length; i++) {
                            let item = this.state.attachments[i]
                            formData.append(item.name, item)
                        }

                        this.setState(
                            { loading: true },
                            async () => {


                                let result = await propertyService.add(formData)



                                this.setState({ loading: false }, () => {
                                    this.props.history.push('/users/profile/');
                                })

                            }
                        )
                    }
                    }

                    render={({ values, errors, status, touched, isSubmitting }) => (
                        <>

                            <div id="mapView" className="mob-min"><div className="mapPlaceholder"><span className="fa fa-spin fa-spinner"></span> Loading map...</div></div>

                            <div id="content" className="mob-max">
                                <div className="rightContainer">
                                    <h1 className="arabic-english">اضافة عقار</h1>
                                    <Form role="form" encType="multipart/form-data" >
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                                                <div className={
                                                    getIn(errors, 'details.title') && getIn(touched, 'details.title') ? 'form-group has-error' : 'form-group'
                                                } >
                                                    <label className="arabic-english">اسم العقار</label>

                                                    <Field
                                                        name="details.title"
                                                        className="form-control arabic-english"
                                                        type="text"
                                                        placeholder="اسم العقار" />
                                                    <ErrorMessage name="details.title" component="div" className="help-block error" />

                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                                <div className={getIn(errors, "details.price") && getIn(touched, "details.price") ? 'form-group has-error' : 'form-group'} >
                                                    <label className="arabic-english">السعر</label>
                                                    <div className="input-group">
                                                        <div className="input-group-addon arabic">ج.م</div>

                                                        <Field
                                                            name="details.price"
                                                            className="form-control arabic-english"
                                                            type="text"
                                                            placeholder="السعر بالجنيه المصري" />
                                                    </div>
                                                    <ErrorMessage name="details.price" component="div" className="help-block error" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">

                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className={getIn(errors, "details.description") && getIn(touched, "details.description") ? 'form-group has-error' : 'form-group'} >
                                                    <label className="arabic" >الوصف</label>
                                                    <Field
                                                        name="details.description"
                                                        className="form-control arabic-english"
                                                        component="textarea"
                                                        rows="4"
                                                        placeholder="الوصف" />
                                                    <ErrorMessage name="details.description" component="div" className="help-block error" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                                <div className="form-group" >
                                                    <div className="">
                                                        <label className="arabic">نوع العقار</label>
                                                        <div className="clearfix"></div>

                                                        <Field as="select"
                                                            name="details.type"
                                                            className="btn btn-white dropdown-toggle"
                                                            style={{ "width": "100%" }}
                                                        >
                                                            <option value="apartment" className="arabic" >شقة</option>
                                                            <option value="land" className="arabic">أرض</option>
                                                            <option value="commercial" className="arabic" >تجاري</option>

                                                        </Field>

                                                    </div>

                                                </div>
                                            </div>


                                        </div>


                                        <div className="row">

                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className={getIn(errors, "details.location.address") && getIn(touched, "details.location.address") ? 'form-group has-error' : 'form-group'} >
                                                    <label className="arabic">العنوان
                                                        <span id="latitude" className="label label-default hidden"></span>
                                                        <span id="longitude" className="label label-default hidden"></span></label>
                                                    {/* <input className="form-control arabic-english" type="text" id="address" placeholder="العنوان" autoComplete="off" /> */}

                                                    <Field
                                                        name="details.location.address"
                                                        id="address"
                                                        className="form-control arabic-english"
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder="العنوان" />
                                                    <ErrorMessage name="details.location.address" component="div" className="help-block error" />

                                                    <p className="help-block arabic">برجاء تحريك العلامة باستخدام الخريطة</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                                <div className={getIn(errors, "details.room") && getIn(touched, "details.room") ? 'form-group has-error' : 'form-group'} >
                                                    <label className="arabic">الغرف</label>
                                                    <Field
                                                        name="details.room"
                                                        className="form-control arabic-english"
                                                        type="text"
                                                        placeholder="الغرف" />

                                                    <ErrorMessage name="details.room" component="div" className="help-block error" />
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                                <div className={getIn(errors, "details.bathroom") && getIn(touched, "details.bathroom") ? 'form-group has-error' : 'form-group'} >
                                                    <label className="arabic">الحمامات</label>
                                                    <Field
                                                        name="details.bathroom"
                                                        className="form-control arabic-english"
                                                        type="text"
                                                        placeholder="الحمامات" />

                                                    <ErrorMessage name="details.bathroom" component="div" className="help-block error" />
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                                <div className={getIn(errors, "details.area") && getIn(touched, "details.area") ? 'form-group has-error' : 'form-group'} >
                                                    <label className="arabic">المساحة</label>
                                                    <div className="input-group">
                                                        <Field
                                                            name="details.area"
                                                            className="form-control arabic-english"
                                                            type="text"
                                                            placeholder="المساحة" />
                                                        <div className="input-group-addon arabic-english">2 متر</div>
                                                    </div>

                                                    <ErrorMessage name="details.area" component="div" className="help-block error" />
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                                <div className="btn-group">
                                                    <label className="arabic">الغرض</label>
                                                    <div className="clearfix"></div>


                                                    <Field as="select"
                                                        name="details.purpose"
                                                        className="btn btn-white dropdown-toggle"
                                                    >
                                                        <option value="البيع" className="arabic" >البيع</option>
                                                        <option value="الايجار" className="arabic">الايجار</option>
                                                        <option value="البيع\الايجار" className="arabic" >البيع\الايجار</option>

                                                    </Field>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="form-group">


                                                    <label className="arabic">الصور</label>

                                                    <input type="file"
                                                        onChange={this.fileSelectedHandler}
                                                        name="attachments"
                                                        className="file " multiple data-show-upload="false" data-show-caption="false" data-show-remove="false" accept="image/jpeg,image/png,image/jpg"
                                                        data-browse-class="btn btn-o btn-default arabic" data-browse-label="الصور" />
                                                    <p className="help-block arabic">يمكن اختيار اكثر من صورة</p>

                                                </div>



                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label>Amenities</label>
                                                    <div className="checkbox custom-checkbox"><label className="arabic"><Field name='details.features.furnished' type="checkbox" /><span className="fa fa-check"></span> مفروش</label></div>
                                                    <div className="checkbox custom-checkbox"><label className="arabic"><Field name='details.features.electricty' type="checkbox" /><span className="fa fa-check"></span> كهرباء</label></div>
                                                    <div className="checkbox custom-checkbox"><label className="arabic"><Field name='details.features.water' type="checkbox" /><span className="fa fa-check"></span> مياه</label></div>
                                                    <div className="checkbox custom-checkbox"><label className="arabic"><Field name='details.features.gas' type="checkbox" /><span className="fa fa-check"></span> غاز</label></div>
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label>&nbsp;</label>
                                                    <div className="checkbox custom-checkbox"><label className="arabic"><Field name='details.features.air_conditioner' type="checkbox" /><span className="fa fa-check"></span>تكييف</label></div>
                                                    <div className="checkbox custom-checkbox"><label className="arabic"><Field name='details.features.water_heater' type="checkbox" /><span className="fa fa-check"></span> سخان</label></div>
                                                    <div className="checkbox custom-checkbox"><label className="arabic"><Field name='details.features.internet' type="checkbox" /><span className="fa fa-check"></span> انترنت</label></div>
                                                    <div className="checkbox custom-checkbox"><label className="arabic"><Field name='details.features.pool' type="checkbox" /><span className="fa fa-check"></span> حمام سباحة</label></div>

                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label>&nbsp;</label>
                                                    <div className="checkbox custom-checkbox"><label className="arabic"><Field name='details.features.telephone' type="checkbox" /><span className="fa fa-check"></span> تليفون</label></div>
                                                    <div className="checkbox custom-checkbox"><label className="arabic"><Field name='details.features.garden' type="checkbox" /><span className="fa fa-check"></span> حديقة</label></div>
                                                    <div className="checkbox custom-checkbox"><label className="arabic"><Field name='details.features.Security' type="checkbox" /><span className="fa fa-check"></span> حراسة</label></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">

                                            {this.state.loading ? <Loader
                                                type="TailSpin"
                                                color="#0eaaa6"
                                                height={50}
                                                width={50}
                                            /> : <button id={this.state.isUpdating ? 'update' : 'submit'} type='submit' className="btn btn-green btn-lg arabic">
                                                {this.state.isUpdating ? 'تعديل' : 'اضافة'}

                                            </button>}

                                        </div>

                                    </Form>
                                </div>



                            </div>

                        </>
                    )}
                />

                )






            </div>




        );
    }
}


export default withRouter(PropertyForm);