import React, {
} from "react";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import $ from 'jquery'
import { userService, authenticationService } from '../../_services';



class CompleteProfile extends React.Component {
    constructor(props) {
        super(props);


        this.loadScript = this.loadScript.bind(this);
        this.removeBodyClass = this.removeBodyClass.bind(this);


    }
    removeBodyClass() {
        $("body").attr("class", "notransition no-hidden");
    }

    loadScript(src) {
        const script = document.createElement("script");
        script.src = src;
        script.async = false;
        document.body.appendChild(script);
    }


    componentDidMount() {

        // this.removeBodyClass();

        this.loadScript('/js/jquery-2.1.1.min.js');
        this.loadScript('/js/jquery-ui.min.js');
        this.loadScript('/js/jquery-ui-touch-punch.js');
        this.loadScript('/js/jquery.placeholder.js');
        this.loadScript('/js/bootstrap.js');
        this.loadScript('/js/jquery.touchSwipe.min.js');
        this.loadScript('/js/jquery.visible.js');
        this.loadScript('/js/signup.js', "text/javascript");

    }


    render() {
        return (
            <div>

                <Formik
                    initialValues={{
                        phone_number: ''
                    }}

                    validationSchema={Yup.object().shape({
                        phone_number: Yup.string().matches(/^(01)\d{9}$/, 'برجاء ادخال رقم صحيح').required('مطلوب'),
                        // 
                    }
                    )
                    }

                    onSubmit={async (values, { setSubmitting }) => {
                        console.log('submitting for complete profile ...')
                        let response = await userService.completeProfile({ phone_number: values.phone_number })
                        let user = response.user
                        window.$(this.modal).modal('hide');

                        if (!user) {
                            this.props.history.push("/");
                        }
                        else {

                            const { from } = this.props.location.state || { from: { pathname: "/" } };

                            this.props.history.push("/");
                        }
                    }
                    }

                    render={({ values, errors, status, touched, isSubmitting, }) => (

                        <div className="modal fade" id="signup" ref={modal => this.modal = modal} role="dialog" aria-labelledby="signupLabel" aria-hidden="true">
                            <div className="modal-dialog modal-sm">
                                <div className="signLogo osLight"><span className="fa fa-home"></span> Newgar</div>
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title arabic" id="exampleModalLabel">رقم الهاتف للتواصل</h5>

                                    </div>
                                    <div className="modal-body">
                                        <Form role="form">

                                            <div className={"form-group" + (errors.phone_number && touched.phone_number ? ' has-error' : '')} >
                                                <Field
                                                    name="phone_number"
                                                    className="form-control"
                                                    type="text"

                                                    placeholder="رقم الهاتف" />
                                                <ErrorMessage name="phone_number" component="div" className="help-block error" />


                                            </div>
                                            <div className="form-group">
                                                <button
                                                    style={{ "width": "100%" }}
                                                    type='submit' className="btn btn-green arabic" >تسجيل</button>
                                            </div>




                                        </Form>
                                    </div>
                                </div>
                                <div className="signFooter">Newgar<br />© 2020</div>
                            </div>
                        </div>


                    )}
                />

            )



            </div>

        );
    }
}


export default CompleteProfile;